import React from "react"
import SEO from "../components/seo"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"

const NotFoundPage = ({ location }) => {
  const langKey = getCurrentLangKey(["en_us", "ka"], "en_us", location.pathname)
  const homeLink = langKey === "en-us" ? "/" : `/${langKey}/`
  const langsMenu = getLangs(
    ["en_us", "ka"],
    langKey,
    getUrlForLang(homeLink, location.pathname)
  )
  console.log("mn ", langsMenu)
  return (
    <>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </>
  )
}

export default NotFoundPage
